import BirthdayTrigger from './forms/birthday-trigger.js';
import SelectWithOtherTrigger from './forms/select-with-other-trigger.js';

export default function regEmail() {

    if (document.querySelector('.reg-email')) {

        const form = document.querySelector('.reg-email form.form');

        if (form) {
            const modalName = form.getAttribute('data-modal-ok');
            let modal = null;
            if (modalName && window.asteq.modals) {
                const backUrl = form.getAttribute('data-back-url');
                if (backUrl) {
                    window.asteq.modals.afterInitFuncitons.push(() => {
                        modal = window.asteq.modals.list[modalName];
                        const okButton = modal.element.querySelector('[data-button-ok]');
                        if (okButton) {
                            okButton.addEventListener('click', () => {
                                window.location.href = backUrl;
                            });
                        }
                    });
                }
            }

            BirthdayTrigger.findAndInit(form);
            SelectWithOtherTrigger.findAndInit(form);

            const _urlGenerateCode = form.getAttribute('action');

            const blocks = form.querySelectorAll('[data-block]');
            const controls = form.querySelectorAll('[data-control]');
            const fieldErrorSubmit = form.querySelector('[data-submit-error]');

            const controlEmail = form.querySelector('#email');
            const controlCodeEmail = form.querySelector('#codeEmail');

            const objToSubmitForm = {};
            let canSubmit = true;



            // Если в существуют контролы EMAIL
            if (controlEmail && controlCodeEmail) {

                // Почта уже подтверждена
                // emailConfirmed
                const buttonConfirmEmail = controlEmail.querySelector('button');
                const textErrorEmail = controlEmail.querySelector('.form-control__info_error');
                const textSuccessEmail = controlEmail.querySelector('.form-control__info_success');

                const buttonConfirmCode = controlCodeEmail.querySelector('button');
                const textErrorCode = controlCodeEmail.querySelector('.form-control__info_error')
                const textSuccessCode = controlCodeEmail.querySelector('.form-control__info_success');

                // Клик на "Подвердить почту"
                buttonConfirmEmail.addEventListener('click', (e) => {
                    e.preventDefault();
                    const dataEmail = new FormData();
                    const xhr = new XMLHttpRequest();

                    dataEmail.append("email", form.email.value);
                    dataEmail.append("template", form.template.value);
                    dataEmail.append("parameters", form.parameters.value);
                    dataEmail.append("action", "generateCode");


                    xhr.open("POST", _urlGenerateCode, true);

                    xhr.onload = function (event) {
                        const json = JSON.parse(xhr.response);
                        const ok = xhr.status === 200;
                        console.log('loadEmail')

                        if (ok) {
                            console.log('successEmail', json.message)

                            textErrorEmail.textContent = '';
                            textSuccessEmail.textContent = json.message;
                            controlCodeEmail.classList.remove('hidden');

                        } else {
                            console.warn('errorEmail', json.message)

                            textSuccessEmail.textContent = '';
                            textErrorEmail.textContent = json.message;
                            controlCodeEmail.classList.add('hidden');

                        }
                    };

                    xhr.error = function (event) {
                        const json = JSON.parse(xhr.response);
                        console.warn('errorEmail', json.message);

                        textSuccessEmail.textContent = '';
                        textErrorEmail.textContent = json.message;
                        controlCodeEmail.classList.add('hidden');
                    }

                    xhr.send(dataEmail);
                })

                buttonConfirmCode.addEventListener('click', (e) => {
                    e.preventDefault();
                    console.log('try to check code');

                    const data = new FormData();
                    data.append("email", form.email.value);
                    data.append("code", form.code.value);
                    data.append("template", form.template.value);
                    data.append("parameters", form.parameters.value);
                    data.append("action", "confirmCode");

                    console.log(data);

                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", _urlGenerateCode, true);
                    xhr.onload = function (event) {
                        const json = JSON.parse(xhr.response);
                        console.log('loadCode', json)

                        if (xhr.status === 200) {
                            console.log('success', json)
                            textErrorCode.textContent = '';
                            textSuccessCode.textContent = json.message;
                        } else {
                            const json = JSON.parse(xhr.response);
                            console.warn('errorCode', json);
                            textErrorCode.textContent = json.message;
                        }
                    };
                    xhr.onerror = function (event) {
                        const json = JSON.parse(xhr.response);
                        console.warn('error2 - errorCode', json)
                        textErrorCode.textContent = json.message
                    };
                    xhr.send(data);
                })
            }


            form.addEventListener('submit', (event) => {

                event.preventDefault();
                canSubmit = true;

                if (controls && controls.length > 0) {
                    console.log('controls', controls)

                    controls.forEach((control) => {
                        const input = control.querySelector('[data-control-input]');
                        const inputTextError = control.querySelector('.form-control__info.form-control__info_error');

                        if (input) {
                            const inputName = input.getAttribute('name');
                            const inputType = input.getAttribute('data-control-input');
                            const inputRequired = input.hasAttribute('data-control-required');


                            if (checkInput(inputName, inputType)) {
                                let inputValue =  getInputValue(input, inputType, inputRequired);
                                console.log(inputName, inputType, inputValue);

                                input.addEventListener('change', () => {
                                    inputValue = getInputValue(input, inputType, inputRequired);

                                    if (inputValueIsValid(inputValue, inputType, inputRequired)) {
                                        inputTextError.textContent = '';
                                    } else {
                                        inputTextError.textContent = getErrorMessage(inputType, inputValue);
                                    }
                                })

                                if (inputValueIsValid(inputValue, inputType, inputRequired)) {
                                    objToSubmitForm[inputName] = inputValue;
                                } else {
                                    console.warn('Value not valid. Name: ', inputName, 'Type: ', inputType, 'Value: ', inputValue)
                                    inputTextError.textContent = getErrorMessage(inputType);
                                    canSubmit = false;
                                }
                            } else {
                                console.warn('checkInput fail', inputName, inputType);
                                canSubmit = false;
                            }
                        }
                    })
                }

                if (!canSubmit) {
                    console.log('can"t submit')

                    document.querySelector('body').scrollIntoView({
                        block: 'start',
                        behavior: 'smooth'
                    });
                } else {
                    console.log('canSubmit', objToSubmitForm)
                    const xhr = new XMLHttpRequest();
                    const data = new FormData();

                    for (let key in objToSubmitForm) {
                        data.append(key, objToSubmitForm[key] || null)
                    }

                    // Hidden data
                    data.append('template', form.template.value)
                    data.append('parameters', form.parameters.value)
                    data.append('action', form.action.value)
                    data.append('pdf', form.pdf.value)
                    data.append('course', form.course.value)

                    xhr.open("POST", form.getAttribute('action'), true);
                    xhr.onload = function (event) {
                        const json = JSON.parse(xhr.response);
                        console.log('loadCode', json)

                        if (xhr.status === 200) {
                            console.log('success submit', json);
                            form.reset();
                            fieldErrorSubmit.innerHTML = '';
                            modal.open();
                        } else {
                            console.warn('error', json);
                            if (fieldErrorSubmit) {
                                fieldErrorSubmit.innerHTML = json.message
                            }
                        }
                    };
                    xhr.onerror = function (event) {
                        const json = JSON.parse(xhr.response);
                        console.warn('oneError', json)
                        if (fieldErrorSubmit) {
                            fieldErrorSubmit.innerHTML = json.message
                        }
                    };
                    xhr.send(data);
                }
            })

            function checkInput (name, type) {
                if (name && type) {
                    return true;
                }
                return false;
            }

            function getInputValue(input, type) {
                if (input && type) {
                    switch(type) {
                        case 'text':
                            return input.value.trim() ? input.value.trim() : null;
                        case 'number':
                            return input.value.trim() ? input.value.trim() : null;
                        case 'select':
                            return input.value;
                        case 'checkbox':
                            return input.checked;
                        case 'date':
                            return input.value;
                        case 'email':
                            return input.value.trim() ? input.value.trim() : null
                        case 'default':
                            return null;
                    }
                }
                return null;
            }

            function inputValueIsValid(value, type, required = false) {
                if (type) {
                    if (required) {
                        switch(type) {
                            case 'text':
                                return value && value.length > 0 ? true : false;
                            case 'number':
                                return value && value.length > 0 && !isNaN(value) ? true : false;
                            case 'select':
                                return value ? true : false;
                            case 'checkbox':
                                return value;
                            case 'date':
                                return !isNaN(new Date(value)) ? true : false;
                            case 'email':
                                return true;
                            case 'default':
                                return false;
                        }
                    } else {
                        return true;
                    }
                }
                return false;
            }

            function getErrorMessage(type, value) {
                if (type) {
                    switch(type) {
                        case 'text':
                            return 'Заполните поле';
                        case 'number':
                            if (value && value.length > 0) {
                                if (isNaN(value)) {
                                    return 'Введите только числовое значение';
                                }
                            }
                            return 'Заполните поле';
                        case 'select':
                            return 'Выберите вариант';
                        case 'checkbox':
                            return 'Поставьте галочку';
                        case 'date':
                            return 'Введите корректную дату';
                        case 'default':
                            return false;
                    }
                }
                console.warn('error');
                return 'Заполните поле';
            }

            // Переключение блоков | уровень образования [data-blocks]

            if (blocks && blocks.length > 0) {

                const selectEducation = form.querySelector('[data-select-education]');

                blocks.forEach((block) => {
                    block.classList.add('hidden');
                });

                if (selectEducation) {
                    selectEducation.addEventListener('change', (e) => {
                        const selectedOption = selectEducation.selectedOptions[0].getAttribute('data-show');

                        blocks.forEach((block) => {
                            const controls = block.querySelectorAll('[data-control]');

                            if (block && block.hasAttribute(`data-block-${selectedOption}`)) {
                                block.classList.remove('hidden');

                                if (controls && controls.length > 0) {
                                    controls.forEach((control) => {
                                        const input = control.querySelector('[data-control-input]');

                                        if (input) {
                                            input.setAttribute('data-control-required', '');
                                        }
                                    })
                                }
                            } else {
                                block.classList.add('hidden');

                                if (controls && controls.length > 0) {
                                    controls.forEach((control) => {
                                        const input = control.querySelector('[data-control-input]');

                                        if (input) {
                                            input.removeAttribute('data-control-required');
                                        }
                                    })
                                }
                            }
                        })
                    })
                }
            }
        }
    }
}

