export default class SelectWithOtherTrigger {
    constructor(selectEl, relatedEls) {
        this.selectEl = selectEl;
        this.relatedEls = relatedEls;
        if (this.selectEl) {
            this.selectEl.addEventListener('change', this.onSelectChange.bind(this));
        }
    }

    onSelectChange(e) {
        const hiddenClass = 'hidden';
        const inputSelector = '[data-control-input]';

        console.log('onchange', this.relatedEls);
        if (this.selectEl.value) {
            this.relatedEls.forEach((el) => {
                el.classList.add(hiddenClass);
            });
        } else {
            this.relatedEls.forEach((el) => {
                el.classList.remove(hiddenClass);
            });
        }
    }

    static findAndInit(form) {
        const attr = 'data-select-with-other';

        const selectEls = form.querySelectorAll(`[${attr}]`);
        selectEls.forEach((el) => {
            const selectEl = el.querySelector('select');
            if (!selectEl) {
                return;
            }
            const inputName = selectEl.getAttribute('name');
            if (!inputName) {
                return;
            }
            const relatedEls = form.querySelectorAll(`[data-rel=${inputName}]`);
            new this(selectEl, relatedEls);
        });
    }
}